const LANGUAGES_LABEL = [
    {
        code: 'he',
        text: 'עברית',
    },
    {
        code: 'en',
        text: 'English',
    },
    {
        code: 'ar',
        text: 'عربيه',
    },
    {
        code: 'ru',
        text: 'Русский',
    },
    {
        code: 'de',
        text: 'Deutsch',
    },
];
const TRIP_MODES = [
    {
        key: 'bus',
        value: 'BUS',
    },
    {
        key: 'train',
        value: 'RAIL',
    },
    {
        key: 'tram',
        value: 'TRAM',
    },
    {
        key: 'carmelit',
        value: 'CABLE_CAR',
    },
    {
        key: 'sherutTaxi',
        value: 'SHARE_TAXI',
    },
];

const ROUTE_OPTIONS = [
{ key: 'bestRoute', name: 'optimize', value: 'QUICK' },
{ key: 'fewerTransfers', name: 'transferPenalty', value: 600 },
{ key: 'lessWalking', name: 'walkReluctance', value: 10 },
];

const JOURNEY_MODES = {
    bus: 'BUS',
    car: 'CAR',
    bicycle: 'BICYCLE',
    walk: 'WALK'
};

const CURRENCY_TYPED = {
    euro: 'EURO',
    dollar: 'DOLLAR',
    shekel: 'SHEKEL'
};


module.exports = {
    LANGUAGES_LABEL,
    TRIP_MODES,
    ROUTE_OPTIONS,
    JOURNEY_MODES,
    CURRENCY_TYPED
};